import './MainContent.scss';
import 'rc-slider/assets/index.css';
import 'react-loading-skeleton/dist/skeleton.css';

import Slider from 'rc-slider';
import Skeleton from 'react-loading-skeleton';
import React, {useCallback, useEffect, useState, useRef } from 'react';
import { Row, Col, Form, InputGroup, Container, Button, Popover, Overlay, Modal, OverlayTrigger } from 'react-bootstrap';

// Components
import TableBody from 'components/Table/body';
import NavbarComponent from 'components/Dashboard/NavbarComponent/NavbarComponent';
import FilterComponent from 'components/Dashboard/FilterComponent/FilterComponent';

// Utils
import downloadCSV from 'utils/downloadCSV';

// Hooks
import useFilters from 'hooks/useFilter';
import useGetOrgs from 'hooks/useGetOrgs';
import useGetJobs from 'hooks/useGetJobs';
import useSetNotes from 'hooks/useSetNotes';
import useGetFields from 'hooks/useGetFields';
import useSetPriority from 'hooks/useSetPriority';
import useDynamicTable from 'hooks/useDynamicTable';
import { useDebounce } from 'usehooks-ts';

// Stores
import { useJobStore } from 'stores/jobs';

// Assets
import Warning from 'assets/images/Warning.svg';
import CrossIcon from 'assets/images/icons/icon-cross.svg';
import FilterIcon from 'assets/images/icons/icon-filter.svg';

const MainContent = () => {
  // Queries
  const fields = useGetFields();
  const orgsQuery = useGetOrgs();
  
  // Stores
  const { jobData, daysBuffer, lastEvaluatedKey, setDaysBuffer } = useJobStore();
  
  const [jobid, setjobid] = useState();
  const [email, setEmail] = useState("");
  
  const [smShow, setSmShow] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [, setVisible] = useState(false);
  const [currentVisible, setCurrentVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [filterActive, setfilterActive] = useState(false);
  
  // add comments tooltip state
  const [showComments, setShowComments] = useState(false);
  const [commentsTarget, setCommentsTarget] = useState(null);
  const [radioValue, setRadioValue] = useState("");
  const [notes, setNotes] = useState("");
  const [searchValue, setSearchValue] = useState('');
  
  const setNotesMutation = useSetNotes();
  const setPriorityMutation = useSetPriority();
  const debouncedSearch = useDebounce(searchValue, 300);
  const { setFilters, searchParams, filters } = useFilters();
  const { tableHead, toggleDisplayAllData, columns, toggleColumn, displayAllData } = useDynamicTable(fields);
  
  const jobsQuery = useGetJobs(searchParams);
  
  useEffect(() => {
    if (debouncedSearch) {
      setFilters([
        ...filters.filter((f) => f.type !== 'roomId'),
        { type: 'roomId', value: searchValue }
      ]);
    }
  }, [debouncedSearch]);
  
  const handleClick = () => {
    setShow(!show);
    setfilterActive(!filterActive);
  };
  
  const searchData = useCallback((e) => {
    const value = e.target.value;
    if (value) {
      setSearchValue(e.target.value);
    } else {
      setFilters([ ...filters.filter((f) => f.type !== 'roomId') ])
    }
  }, []);
  
  const handleComments = (e) => {
    setShowComments(!showComments);
    setCommentsTarget(e.target);
  };
  
  useEffect(() => {
    if (filters.length) {
      applyFilter()
    }
  }, [filters]);
  
  const onCancleClick = (e) => {
    e.preventDefault();
    setFilters([]);
    handleClick();
    setShow(false);
  }
  
  const applyFilter = () => {
    handleVisible();
    setVisible(false);
    handleClick();
    setShow(false);
  };
  
  //popup
  const handleVisible = () => {
    setCurrentVisible(true);
  };
  
  const confirmSetPriority = async () => {
    try {
      await setPriorityMutation.mutateAsync({
        id: jobid,
        priority: parseInt(radioValue),
        email: email,
      });
    } catch (e) {
      console.error(e.message);
    } finally {
      setPopUp(false);
      setSmShow(false);
    }
  };
  
  const submitNotes = async (e) => {
    e.preventDefault();
    try {
      await setNotesMutation.mutateAsync({
        id: jobid,
        notes: notes
      });
    } catch (e) {
      console.error(e.message);
    } finally {
      setShowComments(false);
    }
  };
  
  const columnKeys = tableHead.map((c) => c.key);
  const curatedFilters = filters.filter((f) => !['roomId', 'LastEvaluatedKey'].includes(f.type));
  
  return (
    <React.Fragment>
      <div className="col px-0">
        <NavbarComponent />
      </div>
      <Container className="dashboard__wrapper">
        <Row>
          <Col xs={12} md={12} sm={12} lg={7} xl={7}>
            <div className="search__filter ps-3 ms-2 d-flex">
              <Form onSubmit={(e) => { e.preventDefault(); }}>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    type="search"
                    className="ps-0 search__Input"
                    id="search"
                    placeholder="Search"
                    onChange={searchData}
                  />
                  <span className="tooltiptext">Search by Room ID</span>
                </InputGroup>
              </Form>
              <div className="w-100">
                <div className="d-flex align-items-center">
                  <Button variant="outline-secondary ms-2 me-2 filter__btn" onClick={handleClick}>
                    <img src={FilterIcon} alt="filter icon" className="me-3" />
                    Filters
                  </Button>
                  
                  <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={
                    <Popover id="popover-basic">
                      <Popover.Header as="div">
                        <Form.Check
                          onChange={toggleDisplayAllData}
                          style={{ width: 145 }}
                          checked={displayAllData}
                          type="checkbox"
                          id="display-all-data"
                          label="Select all"
                        />
                      </Popover.Header>
                      <Popover.Body style={{ height: 300, overflowY: 'scroll'}}>
                        <Form>
                          {
                            columns.map(({ name, selected }, i) => {
                              return (
                                <Form.Check
                                  key={i + name}
                                  onChange={(e) => toggleColumn(name, e.target.checked)}
                                  style={{ width: 145 }}
                                  type="checkbox"
                                  id="display-all-data"
                                  checked={selected}
                                  label={name}
                                />
                              )
                            })
                          }
                        </Form>
                      </Popover.Body>
                    </Popover>
                  }>
                    <Button variant="outline-secondary filter__btn me-2">
                      <i className="bi bi-table text-secondary me-2" />
                      <span className="text-secondary">Select columns</span>
                    </Button>
                  </OverlayTrigger>
                  
                  <Button variant="outline-secondary filter__btn" onClick={() => downloadCSV(jobData, columnKeys)}>
                    <i className="bi bi-file-earmark-arrow-down text-secondary" />
                    <span className="text-secondary">CSV</span>
                  </Button>
                </div>
                
                {show ? (
                  <>
                    <div className="filterBackground" onClick={() => {
                      setShow(false);
                      setfilterActive(false);
                    }}></div>
                    <div
                      className="tooltip-popup"
                      id="popover-contained"
                      onHide={() => { setShow(false); setfilterActive(false) }}
                    >
                      <div className="filter__title">Add filter</div>
                      <div className="dropdown__body">
                        <Form onSubmit={(e) => { e.preventDefault(); }}>
                          <FilterComponent
                            filterData={setFilters}
                            data={jobData}
                            organisations={orgsQuery.data?.map(({ organization }) => organization)}
                            handleVisible={handleVisible}
                            cancleClick={(e) => { onCancleClick(e) }}
                          />
                        </Form>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </Col>
          
          <Col xs={12} md={12} sm={12} lg={7} xl={4} style={{ marginTop: -4 }}>
            <Row className="align-items-center">
              <Col>Max days:{daysBuffer}</Col>
            </Row>
            <Row className="align-items-center">
              <Col xl={7}>
                <Slider defaultValue={daysBuffer} min={1} max={365} step={1} onChangeComplete={(daysBuffer) => {
                  setFilters([ ...filters.filter((f) => f.type !== 'LastEvaluatedKey') ]);
                  setDaysBuffer(daysBuffer);
                }} />
              </Col>
            </Row>
          </Col>
        </Row>
        
        {currentVisible && curatedFilters.length ?
          (<Row className="pb-3">
            <Col xs={12} md={12} sm={6} lg={5} xl={5} className="show-filter">
              <div className="d-flex align-items-center mx-2">
                <span className="current-filter">Current filters:</span>
                {curatedFilters.map((data) => {
                  return (
                    <React.Fragment>
                      <button className="btn filter-processing filter-name mx-2">
                        {data.value.length > 5 ? data.value.substring(0, 5) + "..." : data.value}
                        <i onClick={() => {
                          setFilters(filters.filter((item) => data.type !== item.type && data.value !== item.value));
                        }}>
                          <img src={CrossIcon} alt="cross icon" className="p-2" />
                        </i>
                      </button>
                    </React.Fragment>
                  );
                })}
              </div>
            </Col>
          </Row>) : null
        }
        
        <Row>
          <Col xl={12}>
            {(!jobData.length && jobsQuery.isLoading) && (
              <>
                <Skeleton className="mb-4" height={50} />
                <Skeleton count={50} />
              </>
            )}
            
            <TableBody
              headings={tableHead}
              jobData={jobData}
              isLoading={jobsQuery.isLoading}
              lastEvaluatedKey={lastEvaluatedKey}
              setPriority={(post) => {
                setSmShow(true);
                setjobid(post.jobId);
                setEmail(post.email);
              }}
              loadMore={() => {
                setFilters([
                  ...filters.filter((f) => f.type !== 'LastEvaluatedKey'),
                  { type: 'LastEvaluatedKey', value: lastEvaluatedKey }
                ]);
              }}
              handleComments={(e, post) => {
                handleComments(e);
                setNotes(post.notes);
                setjobid(post.jobId);
              }}
            />
            
          </Col>
        </Row>
      </Container>
      
      {/* handle comments tooltip */}
      <Overlay
        show={showComments}
        target={commentsTarget}
        key={commentsTarget?.key}
        placement="bottom"
        containerPadding={20}
      >
        <Popover className="p-2" id="comments-popover">
          <Popover.Header
            as="h3"
            className="py-3 ps-1 filter__title  border-bottom-0 d-flex justify-content-between"
          >
            Add comments
            <img
              alt="cross icon"
              src={CrossIcon}
              className="d-flex justify-content-end"
              onClick={(e) => {
                e.preventDefault();
                setShowComments(false);
              }}
            />
          </Popover.Header>
          <Popover.Body className="p-0">
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  rows={6}
                  className="text-area-css"
                  defaultValue={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                />
              </Form.Group>
              <div className="popover__footer d-flex justify-content-between my-3">
                <button
                  className="clear__filter"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowComments(false);
                  }}
                >
                  Cancel
                </button>
                <button className="apply__filter" onClick={submitNotes}>
                  Save
                </button>
              </div>
            </Form>
          </Popover.Body>
        </Popover>
      </Overlay>
      <Modal
        centered
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="priority-header">
          <Modal.Title id="example-modal-sizes-title-sm" className="p-title">
            Change Priority
          </Modal.Title>
          <span>Choose the new priority</span>
        </Modal.Header>
        <Modal.Body>
          <Form className="radio__css">
            <div className="radio-css">
              <Form.Check
                type="radio"
                aria-label="radio 1"
                name="default"
                className="mb-1"
                value={1}
                onChange={(e) => setRadioValue(e.target.value)}
              />
              <span className="ms-4 mb-1">1</span>
            </div>
            <div className="radio-css">
              <Form.Check
                type="radio"
                aria-label="radio 2"
                name="default"
                className="mt-1"
                value={0}
                onChange={(e) => setRadioValue(e.target.value)}
              />
              <span className="ms-4 mt-1">0</span>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="priority-footer">
          <Button
            variant="link"
            className="btn-cancel"
            onClick={() => setSmShow(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="btn-change"
            onClick={() => {
              setPopUp(true);
            }}
          >
            Change Priority
          </Button>
        </Modal.Footer>
      </Modal>
      {popup && (
        <Modal
          centered
          size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header className="priority-header">
            <Modal.Title id="example-modal-sizes-title-sm" className="p-title">
              <img src={Warning} alt="waring" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="main-warning-body">
            <div className="warning-change">
              <span className="w-parent">Change Priority</span>
            </div>
            <div className="warning-change">
              <span className="w-child">Are you sure you want to change</span>
            </div>
            <div className="warning-change">
              <span className="w-child">the priority this job?</span>
            </div>
          </Modal.Body>
          <Modal.Footer className="priority-footer">
            <Button variant="link" className="btn-cancel" onClick={() => setSmShow(false)}>
              Cancel
            </Button>
            <Button variant="primary" className="btn-change" onClick={confirmSetPriority}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default MainContent;
