import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import MustContainItem from "../SignUpScreen/MustContainItem";
import SideComponent from "../SideComponent";
import BrandLogo from "../../../assets/images/hosta-ai-Logo-black.png";
import EyeIcon from "../../../assets/images/icons/icon-eye.svg";
import { resetPasswordWithCode } from "stores/auth";
import CloseIcon from "../../../assets/images/icons/popup-close.svg";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [show, setShow] = useState(true);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorVerificationCode, setVerificationCode] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [showButton, setShowButton] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  // booleans for password validations
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [contains8C, setContains8C] = useState(false); // min 8 characters

  // checks all validations are true
  const [allValid, setAllValid] = useState(false);
  const [showPasswordCheckContainer, setShowPasswordCheckContainer] =
    useState(false);
  const [viewpassword, setViewPass] = useState(true);
  const [popup, setPopup] = useState(false);
  const [resetPasswordStatus, setResetPasswordStatus] = useState("");

  const handlePasswordView = () => {
    setViewPass(!viewpassword);
    setConfirmPasswordShown(true);
  };
  const handleConfirmPasswordView = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };
  //visible conmfirmpassword
  // useEffect(() => {
  //   if (password.length > 0) {
  //     setShowConfirmPassword(true);
  //   } else {
  //     setShowConfirmPassword(false);
  //   }
  // }, [password]);
  console.log("showButton", showButton);
  useEffect(() => {
    if (
      errorConfirmPassword == null &&
      errorVerificationCode == null &&
      errorConfirmPassword == null &&
      code.length > 0 &&
      password.length > 0 &&
      allValid == true &&
      confirmPassword.length > 0
    ) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [
    errorConfirmPassword,
    errorVerificationCode,
    errorPassword,
    code,
    allValid,
    password,
    confirmPassword,
  ]);
  // labels and state boolean corresponding to each validation
  const mustContainData = [
    ["Mixture of uppercase and lowercase", containsUL],
    ["At least 8 characters", contains8C],
    ["At least one special character (! @ # ? ])", containsSC],
    ["At least one number (0-9)", containsN],
  ];
  console.log(containsUL);
  const Checks = (e) => {
    if (e.target.name === "code") {
      setCode(e.target.value);
      if (e.target.value.length !== 6) {
        setVerificationCode("6 digit code required");
      } else {
        setVerificationCode(null);
      }
    }
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
    if (e.target.name === "confirmPassword") {
      let cfnpsswd = e.target.value;
      setConfirmPassword(e.target.value);
      if (confirmPassword.length > 0) {
        if (password !== cfnpsswd) {
          setErrorConfirmPassword("Passwords do not match");
        } else {
          setErrorConfirmPassword(null);
        }
      }
    }
  };
  const validatePassword = () => {
    // has uppercase letter
    if (
      password.toLowerCase() !== password &&
      password.toUpperCase() !== password
    )
      setContainsUL(true);
    else setContainsUL(false);

    // has lowercase letter
    // if (password.toUpperCase() !== password) setContainsLL(true);
    // else setContainsLL(false);

    // has number
    if (/\d/.test(password)) setContainsN(true);
    else setContainsN(false);

    // has special character
    if (/[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(password))
      setContainsSC(true);
    else setContainsSC(false);

    // has 10 characters
    console.log("length", password.length);
    if (password.length >= 8) setContains8C(true);
    else setContains8C(false);

    // all validations passed
    if (containsUL && containsN && containsSC && contains8C) { setAllValid(true); setShowConfirmPassword(true); }
    else { setAllValid(false); setShowConfirmPassword(true); }
  };

  const handleResetPassword = async () => {
    const email = location.state.email;
    console.log("email", email);
    const res = await resetPasswordWithCode({ email, code, password });
    if (res === "success") {
      // 'Your password has been changed successfully.'
      setResetPasswordStatus("Your password has been changed successfully");
      setPopup(true);
    } else {
      setResetPasswordStatus(res);
      setPopup(true);
    }
  };

  return (
    <React.Fragment>
      <div className="auth__screen">
        <Container>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="auth-screensModals  p-0"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Body className="p-0">
              <Row>
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  xxl={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div className="auth__screenContent login__screenContent forgot__screen">
                    <div className="brand-logo">
                      <img src={BrandLogo} alt="brand-logo" />
                    </div>
                    <div className="forgotPage__title  my-sm-3 my-md-3 my-lg-4 mb-lg-3 my-xxl-5 mb-xxl-3">
                      <h4 className="mb-0">Change password</h4>
                    </div>
                    <div className="page__description">
                      <p className="mb-md-3 mb-sm-4 mb-lg-5 mb-xxl-5">
                        Please the verification code sent to your email and your
                        new password
                      </p>
                    </div>
                    <div className="form__section">
                      <Form>
                        <div
                          className={
                            errorVerificationCode
                              ? "field-wrapper mb-1"
                              : "field-wrapper mb-4"
                          }
                        >
                          <input
                            maxLength={6}
                            type="number"
                            name="code"
                            id="code"
                            className={
                              errorVerificationCode ? "error-input" : ""
                            }
                            placeholder="6 Digit Code"
                            onChange={(e) => {
                              Checks(e);
                            }}
                            value={code}
                            required
                          />
                          <div
                            className={
                              errorVerificationCode
                                ? "field-placeholder error-span"
                                : "field-placeholder"
                            }
                          >
                            <span>Verification Code</span>
                          </div>
                        </div>
                        {errorVerificationCode && (
                          <p className="error mb-4">{errorVerificationCode}</p>
                        )}
                        <div className="field-wrapper mb-4">
                          <input
                            type={viewpassword ? "password" : "text"}
                            name="password"
                            id="password"
                            placeholder="Enter your new password"
                            onChange={(e) => {
                              Checks(e);
                            }}
                            value={password}
                            required
                            onKeyUp={validatePassword}
                            onFocus={() => setShowPasswordCheckContainer(true)}
                          // onBlur={() => setShowPasswordCheckContainer(false)}
                          />
                          <div className="eye__icon">
                            <img
                              src={EyeIcon}
                              alt="eye-icon"
                              onClick={handlePasswordView}
                            />
                          </div>
                          <div className="field-placeholder">
                            <span>New Password</span>
                          </div>
                        </div>
                        {showPasswordCheckContainer ? (
                          <div className="passwordCheckcointener mb-4">
                            <div className="must-container cfb">
                              {mustContainData.map((data) => (
                                <MustContainItem data={data} />
                              ))}
                            </div>
                          </div>
                        ) : null}
                        {showConfirmPassword && (
                          <div
                            className={
                              errorConfirmPassword
                                ? "field-wrapper mb-1"
                                : "field-wrapper mb-4"
                            }
                          >
                            <input
                              name="confirmPassword"
                              id="confirmPassword"
                              className={
                                errorConfirmPassword ? "error-input" : ""
                              }
                              type={confirmPasswordShown ? "text" : "password"}
                              placeholder="Confirm your new password"
                              onChange={(e) => {
                                Checks(e);
                              }}
                              required
                              value={confirmPassword}
                              onKeyUp={validatePassword}
                            />
                            <div className="eye__icon">
                              <img
                                src={EyeIcon}
                                alt="eye-icon"
                                onClick={handleConfirmPasswordView}
                              />
                            </div>
                            <div
                              className={
                                errorConfirmPassword
                                  ? "field-placeholder error-span"
                                  : "field-placeholder"
                              }
                            ></div>
                            <div className="field-placeholder">
                              <span>Confirm password</span>
                            </div>
                          </div>
                        )}
                        {errorConfirmPassword ? (
                          <span className="error mb-4">
                            {errorConfirmPassword}
                          </span>
                        ) : null}

                        <div className="button__section">
                          <button
                            disabled={showButton}
                            onClick={(e) => {
                              e.preventDefault();
                              handleResetPassword();
                            }}
                          >
                            Change Password
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  xxl={6}
                  className="d-flex justify-content-center d-xs-none d-sm-none d-md-block"
                  id="sidebar-component"
                >
                  <SideComponent />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Container>
      </div>

      <Modal
        backdropClassName="popup__modalOverlay"
        backdrop="static"
        centered
        size="md"
        className="popup__modal"
        show={popup}
        onHide={() => setPopup(false)}
      >
        <Modal.Body className="p-0">
          <div className="popup__content text-center">
            <div className="popup__title">
              <h4>Password Reset</h4>
              <img
                src={CloseIcon}
                alt="close-icon"
                onClick={() => setPopup(false)}
              />
            </div>
            <div className="popup__description">
              <p className="mb-4">{resetPasswordStatus}</p>
              <div className="button__section">
                <button
                  className="popup__btn"
                  onClick={() => {
                    resetPasswordStatus ==
                      "Your password has been changed successfully"
                      ? navigate("/login")
                      : setPopup(false);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ResetPassword;
