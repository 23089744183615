// import "./SignUpScreen.scss";
import React, { useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import SideComponent from "../SideComponent";
import BrandLogo from "../../../assets/images/hosta-ai-Logo-black.png";
import { Link, useNavigate } from "react-router-dom";
import "./SignUpConfirmation.scss";
const SignUpConfirmation = () => {
  const [show, setShow] = useState(true);
  const handleNavigate = useNavigate();

  const [viewpassword, setViewPass] = useState(true);
  // handlePassword view
  const handlePasswordView = () => {
    setViewPass(!viewpassword);
  };
  return (
    <React.Fragment>
      <div className="auth__screen">
        <Container>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="auth-screensModals  p-0"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Body className="p-0">
              <Row>
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  xxl={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div className="auth__screenContent login__screenContent signup__screenContent forgot__screen">
                    <div className="brand-logo">
                      <img src={BrandLogo} alt="brand-logo" />
                    </div>
                    <div className="forgotPage__title  my-sm-3 my-md-3 my-lg-4 mb-lg-3 my-xxl-5 mb-xxl-3">
                      <h4 className="mb-0">Check your email</h4>
                    </div>
                    <div className="page__description">
                      <p className="mb-md-3 mb-sm-4 mb-lg-5 mb-xxl-5">
                        Congratulations! Your account was successfully created.
                        Please check your email and confirm your account.
                      </p>
                    </div>
                    <div className="button__section">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleNavigate("/login");
                        }}
                      >
                        Log in
                      </button>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  xxl={6}
                  className="d-flex justify-content-center d-xs-none d-sm-none d-md-block"
                  id="sidebar-component"
                >
                  <SideComponent />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SignUpConfirmation;
