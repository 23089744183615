import {useQuery} from 'react-query';

// Local modules
import {getFields} from 'services/HostalabServices';

export const QUERY_KEY = 'fields';

const useGetFields = () => {
  return  useQuery({
    retry: 10,
    queryKey: QUERY_KEY,
    queryFn: () => getFields(),
    refetchOnReconnect: 'always',
  });
};

export default useGetFields