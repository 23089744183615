import { useLocalStorage } from 'usehooks-ts';
import { useEffect, useState, useCallback } from 'react';

const tableMappings = {
  priority: { name: "Priority", position: 0},
  roomId: { name: "Room ID", position: 1},
  status: { name: "Status", position: 2},
  claimId: { name: "Claim ID", position: 3},
  stage: { name: "Stage", position: 4},
  org: {name: "Company", position: 5},
  email: { name: "Email", position: 6},
  roomType: { name: "Room Type", position: 7},
  numberOfImages: { name: "# of Images", position: 8},
  timeSubmitted: { name: "Time submitted", position: 9},
  timeLabelInit: { name: "Time labelled", position: 10},
  timeModelled: { name: "Time modelled", position: 11},
  timeQAd: { name: "Time QAd", position: 12},
  timeProcessed: { name: "Time processed", position: 13},
  timeLabelled: { name: "Time to label", position: 14},
  timeModelInit: { name: "Time to model", position: 15},
  durationQA: { name: "Time to QA", position: 16},
  totalTime: { name: "Total time", position: 17},
  notes: { name: "Comments", position: 18},
  address: { name: "Address", position: 19},
};

const tableHeadSize = Object.keys(tableMappings).length
const tableMappingsKeys = Object.keys(tableMappings);
const excludedColumns = ['SAPayload', 'j#roomId#jobId']

const useDynamicTable = (fields) => {
  const [lsSelectedColumns, setLsSelectedColumns] = useLocalStorage('selectedColumns', []);
  const [displayAllData, setDisplayAllData] = useLocalStorage('displayAllData', false);
  
  const [columns, setColumns] = useState([]);
  const [tableHead, setTableHead] = useState([]);

  useEffect(() => {
    const pickedKeys = (fields.data || []).filter((f) => !excludedColumns.includes(f));
    const includedColumns = columns.filter((c) => c.selected).map((c) => c.key);
    const pickedColumns = [...tableMappingsKeys, ...includedColumns];
    const selectedColumns = pickedKeys.map((key) => {
      const isSelected = !lsSelectedColumns.length
        ? pickedColumns.includes(key)
        : lsSelectedColumns.find((ls) => ls.key === key)?.selected;
      
      return {
        key: key,
        name: key,
        selected: displayAllData ? displayAllData : isSelected,
        position: tableMappings?.[key]?.position ?? tableHeadSize,
      };
    });

    setColumns(selectedColumns);
    
    if (!lsSelectedColumns.length) {
      setLsSelectedColumns(selectedColumns);
    }
    
  }, [fields.data]);
  
  useEffect(() => {
    const filteredColumns = columns.filter((c) => c.selected);

    setTableHead(filteredColumns.concat([{ key: 'actions', name: 'Actions', selected: true, position: -1 }]).sort((a, b) => a.position - b.position));
  }, [columns]);
  
  useEffect(() => {
    const pickedColumns = columns.map((column) => {
      return {
        ...column,
        selected: displayAllData ? displayAllData : tableMappingsKeys.includes(column.key),
      }
    });

    if (pickedColumns.length) {
      setColumns(pickedColumns);
    }
  }, [displayAllData]);

  const toggleDisplayAllData = useCallback(() => {
    setDisplayAllData((prevState) => !prevState);
  }, [])
  
  const toggleColumn = useCallback((name, selected) => {
    const pickedColumns = columns.map((column) => {
      return {
        ...column,
        ...(name === column.name && { selected }),
      }
    });

    setColumns(pickedColumns);
    setLsSelectedColumns(pickedColumns);
  }, [columns]);

  return { tableHead, fixedKeys: tableMappingsKeys, toggleDisplayAllData, columns, toggleColumn, displayAllData };
};

export default useDynamicTable;