import 'rc-slider/assets/index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './ClaimsComponent.scss';

import Slider from 'rc-slider';
import Skeleton from 'react-loading-skeleton';
import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Form, Popover, InputGroup, OverlayTrigger, Button } from 'react-bootstrap';

// Components
import TableBody from 'components/Table/claim';
import NavbarComponent from 'components/Dashboard/NavbarComponent/NavbarComponent';
import FilterComponent from 'components/Dashboard/FilterComponent/ClaimsFilterComponent';
// Hooks
import { getClaims } from 'services/HostalabServices';
import useGetOrgs from 'hooks/useGetOrgs';
import FilterIcon from 'assets/images/icons/icon-filter.svg';
import CrossIcon from 'assets/images/icons/icon-cross.svg';
import downloadCSV from 'utils/downloadCSV';

const ClaimsContent = () => {
    // Queries
    const [claims, setClaims] = useState([]);
    const [lastEvalKey, setLastEvalKey] = useState(null);
    const [loading, setLoading] = useState(false);
    const fields = { data: ['organization', 'claimId', 'customerID', 'submitter', 'address', 'dateSubmitted', 'timeSubmitted', 'inferredClaimStatus', 'Initiated', 'Processed', 'Processing', 'Not Processable'] }
    const names = { data: ['Organization', 'Claim Id', 'Customer ID', 'Submitter', 'Address', 'Date Submitted', 'Time Submitted', 'Inferred Claim Status', 'Initiated', 'Processed', 'Processing', 'Not Processable'] }
    const [filters, setFilters] = useState({ daysBuffer: 7, claimId: '' });
    const [selectedCols, setSelectedCols] = useState(fields.data.map(e => e))
    const [show, setShow] = useState(false);
    const orgsQuery = useGetOrgs();

    useEffect(() => {
        handleData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (loading) return;
        setLastEvalKey(null);
        handleData();
    }, [filters])

    const handleData = (evalKey) => {
        setLoading(true);
        const mappedFilters = { maxDays: filters.daysBuffer, claimId: filters.claimId, inferredClaimStatus: filters.status, organization: filters.org }
        getClaims(evalKey, mappedFilters).then(resp => {
            setClaims(evalKey ? claims.concat(resp.claims) : resp.claims);
            setLastEvalKey(resp.LastEvaluatedKey);
            setLoading(false);
        }).catch(ex => {
            setLoading(false);
        })
    }

    const handleEnd = () => {
        if (Boolean(lastEvalKey) && lastEvalKey !== 'null')
            handleData(lastEvalKey, filters);
    }

    const handleCheck = (column) => {
        if (selectedCols.includes(column))
            setSelectedCols(selectedCols.filter(e => e !== column))
        else
            setSelectedCols([...selectedCols, column])
    }

    const tableHead = selectedCols.map((e, idx) => {
        const nameIdx = fields.data.findIndex(x => e === x)
        return { key: e, name: names?.data[nameIdx], selected: true, position: idx }
    })

    const displayFilters = Object.keys(filters).filter(x => {
        return Boolean(filters[x]) && x !== 'daysBuffer'
    }).map(x => {
        return { type: x, value: filters[x] };
    })

    return (
        <React.Fragment>
            <div className="col px-0">
                <NavbarComponent />
            </div>
            <Container className="dashboard__wrapper">
                <Row>
                    <Col xs={12} md={12} sm={12} lg={7} xl={7}>
                        <div className="search__filter ps-3 ms-2 d-flex">
                            <Form onSubmit={(e) => { e.preventDefault(); }}>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-default"
                                        type="search"
                                        className="ps-0 search__Input"
                                        id="search"
                                        placeholder="Search"
                                        value={filters.claimId}
                                        onChange={(evt) => setFilters({ ...filters, claimId: evt.target.value })}
                                    />
                                    <span className="tooltiptext">Search by Claim ID</span>
                                </InputGroup>
                            </Form>
                            <div className="w-100">
                                <div className="d-flex align-items-center">
                                    <Button variant="outline-secondary ms-2 me-2 filter__btn" onClick={() => setShow(true)}>
                                        <img src={FilterIcon} alt="filter icon" className="me-3" />
                                        Filters
                                    </Button>

                                    <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={
                                        <Popover id="popover-basic">
                                            <Popover.Header as="div">
                                                <Form.Check
                                                    style={{ width: 145 }}
                                                    type="checkbox"
                                                    id="display-all-data"
                                                    label="Select all"
                                                    checked={selectedCols.length === fields.data.length}
                                                    onChange={() => { selectedCols.length === fields.data.length ? setSelectedCols([]) : setSelectedCols(fields.data.map(e => e)) }}
                                                />
                                            </Popover.Header>
                                            <Popover.Body style={{ height: 300, overflowY: 'scroll' }}>
                                                <Form>
                                                    {
                                                        fields.data.map((e, i) => {
                                                            return (
                                                                <Form.Check
                                                                    key={i + e}
                                                                    style={{ width: 145 }}
                                                                    type="checkbox"
                                                                    id="display-all-data"
                                                                    checked={selectedCols.includes(e)}
                                                                    onChange={() => handleCheck(e)}
                                                                    label={e}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Form>
                                            </Popover.Body>
                                        </Popover>
                                    }>
                                        <Button variant="outline-secondary filter__btn me-2">
                                            <i className="bi bi-table text-secondary me-2" />
                                            <span className="text-secondary">Select columns</span>
                                        </Button>
                                    </OverlayTrigger>

                                    <Button variant="outline-secondary filter__btn" onClick={() => downloadCSV(claims, selectedCols)}>
                                        <i className="bi bi-file-earmark-arrow-down text-secondary" />
                                        <span className="text-secondary">CSV</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={12} sm={12} lg={7} xl={4} style={{ marginTop: -4 }}>
                        <Row className="align-items-center">
                            <Col>Max days:{filters.daysBuffer}</Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col xl={7}>
                                <Slider defaultValue={filters.daysBuffer} min={1} max={365} step={1} onChangeComplete={(daysBuffer) => {
                                    setFilters({ ...filters, 'daysBuffer': daysBuffer });
                                }} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {displayFilters.length > 0 && <Row className="pb-3">
                    <Col xs={12} md={12} sm={6} lg={5} xl={5} className="show-filter">
                        <div className="d-flex align-items-center mx-2">
                            <span className="current-filter">Current filters:</span>
                            {displayFilters.map(data => {
                                return (
                                    <React.Fragment>
                                        <button className="btn filter-processing filter-name mx-2">
                                            {data.value?.length > 5 ? data.value.substring(0, 5) + "..." : data.value}
                                            <i onClick={() => {
                                                setFilters({ ...filters, [data.type]: undefined });
                                            }}>
                                                <img src={CrossIcon} alt="cross icon" className="p-2" />
                                            </i>
                                        </button>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </Col>
                </Row>}
                <Row>
                    <Col xl={12}>
                        {(loading) && (
                            <>
                                <Skeleton className="mb-4" height={50} />
                                <Skeleton count={50} />
                            </>
                        )}
                        <TableBody
                            headings={tableHead}
                            jobData={claims}
                            handleEnd={handleEnd}
                        />
                    </Col>
                </Row>



                {show &&
                    <>
                        <div className="filterBackground" onClick={() => {
                            setShow(false);
                        }}></div>
                        <div
                            className="tooltip-popup"
                            id="popover-contained"
                            onHide={() => { setShow(false) }}
                        >
                            <div className="filter__title">Add filter</div>
                            <div className="dropdown__body">
                                <Form onSubmit={(e) => { e.preventDefault(); }}>
                                    <FilterComponent
                                        filterData={result => {
                                            const updatedFilters = result.reduce((acc, curr) => {
                                                acc[curr.type] = curr.value;
                                                return acc;
                                            }, { status: undefined, org: undefined });

                                            setFilters({ ...filters, ...updatedFilters });
                                            setShow(false);
                                        }}
                                        data={claims}
                                        organisations={orgsQuery.data?.map(({ organization }) => organization)}
                                        cancleClick={(e) => { setShow(false); setFilters({ ...filters, status: undefined, org: undefined }) }}
                                    />
                                </Form>
                            </div>
                        </div>
                    </>
                }
            </Container>

        </React.Fragment>
    );
};

export default ClaimsContent;