import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import SideComponent from "../SideComponent";
import BrandLogo from "../../../assets/images/hosta-ai-Logo-black.png";
import EyeIcon from "../../../assets/images/icons/icon-eye.svg";
import "./Login.scss";
import CloseIcon from "../../../assets/images/icons/popup-close.svg";
import { useAuthStore } from 'stores/auth';

export const LoginScreen = () => {
  
  const authenticate = useAuthStore((state) => state.authenticate);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)

  const [show, setShow] = useState(true);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [showButton, setShowButton] = useState(true);
  const [viewpassword, setViewPass] = useState(true);

  const [popup, setPopup] = useState(false);
  const [loginStatus, setLoginStatus] = useState("");

  const navigate = useNavigate();
  
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [ isAuthenticated ]);
  
  // handlePassword view
  const handlePasswordView = () => {
    setViewPass(!viewpassword);
  };
  const Checks = (e) => {
    const emailCheck = /\S+@\S+\.\S+/;
    if (e.target.name === "email") {
      setEmail(e.target.value);
      if (e.target.value.length > 0) {
        if (!emailCheck.test(e.target.value.trim())) {
          setErrorEmail("Please enter a valid email address");
        } else {
          setErrorEmail(null);
        }
      }
    }
    if (e.target.name === "password") {
      setPassword(e.target.value);
      // if (
      //   !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
      //     e.target.value
      //   )
      // ) {
      //   setErrorPassword("Invalid password");
      // } else {
      //   setErrorPassword(null);
      // }
    }
  };
  useEffect(() => {
    if (
      errorEmail == null &&
      // errorPassword == null &&
      email.length > 0 &&
      password.length > 0
    ) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [errorEmail, errorPassword, email, password]);
  const handleLogin = async (e) => {
    e.preventDefault();
    authenticate({ email, password });
    /*const res = await dispatch(
      login({
        email,
        password,
      })
    );

    if (res === "login") {
      // navigate("/dashboard");
    } else {
      setLoginStatus(res);
      setPopup(true);
    }*/
  };
  
  return (
    <React.Fragment>
      <div className="auth__screen">
        <Container>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="auth-screensModals  p-0"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Body className="p-0">
              <Row>
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  xxl={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div className="auth__screenContent login__screenContent ">
                    <div className="brand-logo">
                      <img src={BrandLogo} alt="brand-logo" />
                    </div>
                    <div className="page__title  my-sm-3 my-md-3 my-lg-4 my-xxl-5">
                      <h4 className="mb-0">Log In</h4>
                    </div>
                    <div className="form__section">
                      <Form>
                        <div
                          className={
                            errorEmail
                              ? "field-wrapper mb-1"
                              : "field-wrapper mb-4"
                          }
                        >
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className={errorEmail ? "error-input" : ""}
                            placeholder="Enter your email"
                            // onKeyPress={(e) => checkEnter(e)}
                            onChange={(e) => {
                              Checks(e);
                              // setEmail(e.target.value);
                            }}
                            value={email}
                            required
                          />
                          <div
                            className={
                              errorEmail
                                ? "field-placeholder error-span"
                                : "field-placeholder"
                            }
                          >
                            <span>Email</span>
                          </div>
                        </div>
                        {errorEmail && (
                          <p className="error mb-4">{errorEmail}</p>
                        )}
                        <div
                          className={
                            errorPassword
                              ? "field-wrapper mb-1"
                              : "field-wrapper mb-4"
                          }
                        >
                          <input
                            type={viewpassword ? "password" : "text"}
                            name="password"
                            id="password"
                            className={errorPassword ? "error-input" : ""}
                            placeholder="Enter your password"
                            onChange={(e) => Checks(e)}
                            value={password}
                            required
                          />
                          <div className="eye__icon">
                            <img
                              src={EyeIcon}
                              alt="eye-icon"
                              onClick={handlePasswordView}
                            />
                          </div>
                          <div
                            className={
                              errorPassword
                                ? "field-placeholder error-span"
                                : "field-placeholder"
                            }
                          >
                            <span>Password</span>
                          </div>
                        </div>
                        {errorPassword ? (
                          <p className="error">{errorPassword}</p>
                        ) : null}
                        <div className="form-label mb-4">
                          <Link to="/forgot-password" className="forgot-text">
                            Forgot password?
                          </Link>
                        </div>
                        <div className="button__section">
                          <button disabled={showButton} onClick={handleLogin}>
                            Login
                          </button>
                        </div>
                        <Link
                          to="/sign-up"
                          className="already__text d-flex justify-content-center my-4"
                        >
                          Create new account
                        </Link>
                      </Form>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  xxl={6}
                  className="d-flex justify-content-center d-xs-none d-sm-none d-md-block"
                  id="sidebar-component"
                >
                  <SideComponent />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Container>
      </div>

      <Modal
        backdropClassName="popup__modalOverlay"
        backdrop="static"
        centered
        size="md"
        className="popup__modal"
        show={popup}
        onHide={() => setPopup(false)}
      >
        <Modal.Body className="p-0">
          <div className="popup__content text-center">
            <div className="popup__title">
              <h4>Login</h4>
              <img
                src={CloseIcon}
                alt="close-icon"
                onClick={() => setPopup(false)}
              />
            </div>
            <div className="popup__description">
              <p className="mb-4">{loginStatus}</p>
              <div className="button__section">
                <button
                  className="popup__btn"
                  onClick={() => {
                    setPopup(false);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
