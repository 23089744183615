import React, { useEffect, useState } from "react";
import { Row, Col, Container, Modal, Form } from "react-bootstrap";
import SideComponent from "../SideComponent";
import BrandLogo from "../../../assets/images/hosta-ai-Logo-black.png";
import EyeIcon from "../../../assets/images/icons/icon-eye.svg";
import CloseIcon from "../../../assets/images/icons/popup-close.svg";
import axios from "axios";
import "./SignupScreen.scss";
import { Link, useNavigate } from "react-router-dom";
import MustContainItem from "./MustContainItem";
import { signUpApi } from "../../../services/HostalabServices";
const SignUpScreen = () => {
  const [show, setShow] = useState(true);
  const [popup, setPopup] = useState(false);
  // const [password, setViewPass] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState(null);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [showButton, setShowButton] = useState(true);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [ischecked, setchecked] = useState(false);
  const [errorChecked, setCheckPassword] = useState("");

  // booleans for password validations
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [contains8C, setContains8C] = useState(false); // min 8 characters

  // checks all validations are true
  const [allValid, setAllValid] = useState(false);
  const [showPasswordCheckContainer, setShowPasswordCheckContainer] =
    useState(false);
  const [viewpassword, setViewPass] = useState(true);
  const navigate = useNavigate();
  const handlePasswordView = () => {
    setViewPass(!viewpassword);
    setConfirmPasswordShown(true);
  };
  const handleConfirmPasswordView = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };
  //visible conmfirmpassword
  // useEffect(() => {
  //   if (password.length>=8 &&containsUL && containsLL && containsN && containsSC && contains8C ) {
  //     setShowConfirmPassword(true);
  //   } else {
  //     setShowConfirmPassword(false);
  //   }
  // }, [password]);

  useEffect(() => {
    if (
      errorConfirmPassword == null &&
      errorEmail == null &&
      errorConfirmPassword == null &&
      email.length > 0 &&
      password.length > 0 &&
      allValid == true &&
      confirmPassword.length > 0 && 
      ischecked == true
    ) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [
    errorConfirmPassword,
    errorEmail,
    errorPassword,
    email,
    allValid,
    password,
    confirmPassword,
    ischecked
  ]);
  // labels and state boolean corresponding to each validation
  const mustContainData = [
    // ["An uppercase letter (a-z)", containsUL],
    ["Mixture of uppercase and lowercase", containsUL],
    ["At least 8 characters", contains8C],
    ["At least one special character (! @ # ? ])", containsSC],
    ["At least one number (0-9)", containsN],
  ];

  const checkEnter = (e) => {
    // if (e.keyCode === 13 && e.shiftKey === false) {
    e.preventDefault();
    Checks(e);
    // }
  };
  const Checks = async (e) => {
    const emailCheck = /\S+@\S+\.\S+/;

    // console.log("password,confirmpassword", password, confirmPassword);
    if (e.target.name === "email") {
      setEmail(e.target.value);
      if (e.target.value.length > 0) {
        if (!emailCheck.test(e.target.value.trim())) {
          setErrorEmail("Please enter a valid email address");
        } else {
          setErrorEmail(null);
        }
      }
    }
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }

    if (e.target.name === "confirmPassword") {
      let cfnpsswd = e.target.value;
      setConfirmPassword(e.target.value);
      if (confirmPassword.length > 0) {
        if (password !== cfnpsswd) {
          console.log("elsw if");
          setErrorConfirmPassword("Passwords do not match");
        } else {
          setErrorConfirmPassword(null);
        }
      }
    }
  };

  const validatePassword = () => {
    // has uppercase letter
    if (
      password.toLowerCase() !== password &&
      password.toUpperCase() !== password
    )
      setContainsUL(true);
    else setContainsUL(false);

    // has lowercase letter
    // if (password.toUpperCase() !== password) setContainsLL(true);
    // else setContainsLL(false);

    // has number
    if (/\d/.test(password)) setContainsN(true);
    else setContainsN(false);

    // has special character
    if (/[~`!@#$%^&*.+=\-[\]\\';,/{}()_|\\":<>?\s]/g.test(password))
      setContainsSC(true);
    else setContainsSC(false);

    // has 10 characters
    console.log("length", password.length);
    if (password.length >= 8) setContains8C(true);
    else setContains8C(false);

    // all validations passed
    if (containsUL && containsN && containsSC && contains8C) {
      setAllValid(true);
      // setShowConfirmPassword(true);
    }
    else {
      setAllValid(false);
      // setShowConfirmPassword(false);
    }
  };
  const handleSignUp = (e) => {
    e.preventDefault();
    navigate("/sign-up-confirmation");

    // axios
    //   .post("https://reqres.in/api/register", {
    //     email: "eve.holt@reqres.in",
    //     password: "pistol",
    //   })
    //   .then(() => {
    //     navigate("/sign-up-confirmation");
    //   })
    //   .catch(() => {
    //     setPopup(true);
    //   });
    // signUpApi()
  };
  
  return (
    <React.Fragment>
      <div className="auth__screen">
        <Container>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="auth-screensModals  p-0"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Body className="p-0">
              <Row>
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  xxl={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div className="auth__screenContent">
                    <div className="brand-logo">
                      <img src={BrandLogo} alt="brand-logo" />
                    </div>
                    <div className="page__title  my-sm-3 my-md-3 my-lg-4 my-xxl-5">
                      <h4 className="mb-0">Sign up</h4>
                    </div>
                    <div className="form__section">
                      <Form>
                        <div
                          className={
                            errorEmail
                              ? "field-wrapper mb-1"
                              : "field-wrapper mb-4"
                          }
                        >
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className={errorEmail ? "error-input" : ""}
                            placeholder="Enter your email"
                            // onKeyPress={(e) => checkEnter(e)}
                            onChange={(e) => {
                              checkEnter(e);
                              // setEmail(e.target.value);
                            }}
                            value={email}
                            required
                          />
                          <div
                            className={
                              errorEmail
                                ? "field-placeholder error-span"
                                : "field-placeholder"
                            }
                          >
                            <span>Email</span>
                          </div>
                        </div>
                        {errorEmail && (
                          <p className="error mb-4">{errorEmail}</p>
                        )}
                        <div className="field-wrapper mb-4">
                          <input
                            type={viewpassword ? "password" : "text"}
                            name="password"
                            id="password"
                            placeholder="Enter your password"
                            onChange={(e) => {
                              checkEnter(e);
                              // setPassword(e.target.value);
                              // validatePassword()
                            }}
                            value={password}
                            required
                            onKeyUp={validatePassword}
                            onFocus={() => setShowPasswordCheckContainer(true)}
                          // onBlur={() => setShowPasswordCheckContainer(false)}
                          />
                          <div className="eye__icon">
                            <img
                              src={EyeIcon}
                              alt="eye-icon"
                              onClick={handlePasswordView}
                            />
                          </div>
                          <div className="field-placeholder">
                            <span>Password</span>
                          </div>
                        </div>
                        {showPasswordCheckContainer ? (
                          <div className="passwordCheckcointener mb-4">
                            <div className="must-container cfb">
                              {mustContainData.map((data) => (
                                <MustContainItem data={data} />
                              ))}
                            </div>
                          </div>
                        ) : null}
                        {showConfirmPassword && (
                          <div
                            className={
                              errorConfirmPassword
                                ? "field-wrapper mb-1"
                                : "field-wrapper mb-4"
                            }
                          >
                            <input
                              name="confirmPassword"
                              id="confirmPassword"
                              className={
                                errorConfirmPassword ? "error-input" : ""
                              }
                              type={confirmPasswordShown ? "text" : "password"}
                              placeholder="Confirm your password"
                              onChange={(e) => {
                                checkEnter(e);
                                // setConfirmPassword(e.target.value);
                                // validatePassword()
                              }}
                              required
                              value={confirmPassword}
                              onKeyUp={validatePassword}
                              onPaste={(e)=>{e.preventDefault()}}
                            />
                            <div className="eye__icon">
                              <img
                                src={EyeIcon}
                                alt="eye-icon"
                                onClick={handleConfirmPasswordView}
                              />
                            </div>
                            <div
                              className={
                                errorConfirmPassword
                                  ? "field-placeholder error-span"
                                  : "field-placeholder"
                              }
                            ></div>
                            <div className="field-placeholder">
                              <span>Confirm password</span>
                            </div>
                          </div>
                        )}
                        {errorConfirmPassword ? (
                          <span className="error mb-4">
                            {errorConfirmPassword}
                          </span>
                        ) : null}
                        <div className="my-4 form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                            checked={ischecked}
                            onClick={() => {
                              setchecked(!ischecked);
                              // if (ischecked === true) {
                              //    console.log("hey there" + ischecked);
                              //   setCheckPassword("");
                              // }
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck1"
                          >
                            I agree to the
                            <a
                              href="https://hosta.ai/terms-of-use/"
                              className="ms-1"
                              target="_blank"
                              rel="noreferrer"
                            >
                              License and services agreement
                            </a>
                          </label>
                          {/* {errorChecked && !ischecked ? (
                            <span className="ErrorDivEmail chce-error">
                              {errorChecked}
                            </span>
                          ) : null} */}
                        </div>
                        <div className="button__section">
                          <button disabled={showButton} onClick={handleSignUp}>
                            Sign up
                          </button>
                        </div>
                        <Link
                          to="/login"
                          className="already__text d-flex justify-content-center my-4"
                        >
                          Already have an account?
                        </Link>
                      </Form>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  xxl={6}
                  className="d-flex justify-content-center d-xs-none d-sm-none d-md-block"
                  id="sidebar-component"
                >
                  <SideComponent />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
      <Modal
        backdropClassName="popup__modalOverlay"
        backdrop="static"
        centered
        size="md"
        className="popup__modal"
        show={popup}
        onHide={() => setPopup(false)}
      >
        <Modal.Body className="p-0">
          <div className="popup__content text-center">
            <div className="popup__title">
              <h4>Sign Up Failed</h4>
              <img
                src={CloseIcon}
                alt="close-icon"
                onClick={() => setPopup(false)}
              />
            </div>
            <div className="popup__description">
              <p className="mb-4">
                Please contact info@hosta.ai for access to the Hosta a.i.
                Portal.
              </p>
              <div className="button__section">
                <button className="popup__btn" onClick={() => setPopup(false)}>
                  Ok
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default SignUpScreen;
