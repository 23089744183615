import { useMutation, useQueryClient } from 'react-query';

// Local modules
import { putPriorityApi } from 'services/HostalabServices';

// Hooks
import { QUERY_KEY as JOBS_QUERY_KEY } from 'hooks/useGetJobs';

const useSetPriority = () => {
	const queryClient = useQueryClient();
	
	return useMutation({
		mutationFn: (data) => {
			return putPriorityApi({
				id: data.id,
				priority: data.priority,
				email: data.email,
			});
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: [JOBS_QUERY_KEY] })
		},
	});
};

export default useSetPriority;