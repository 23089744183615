import classnames from "classnames";
import UUIDLink from "components/UUIDLink";
import { TableVirtuoso } from 'react-virtuoso'
import { defaultDateFormat, defaultTimeFormat } from "utils/date";

const TableBody = (props) => {
    const {
        headings,
        jobData,
        handleEnd
    } = props;


    return (
        <TableVirtuoso
            style={{ height: '85vh' }}
            data={jobData}
            components={{
                Table: ({ style, ...props }) => <table className="jobs-table" {...props} style={{ ...style }} />,
                TableHead: ({ style, ...props }) => <thead className="table__head" {...props} style={{ ...style }} />,
                EmptyPlaceholder: () => <tbody><td style={{ minWidth: 200 }}>No records were found!</td></tbody>
            }}
            fixedHeaderContent={() => {
                return (
                    <tr>
                        {headings.map(({ name }, index) => {

                            return (
                                <th
                                    className={classnames("table__head__column")}
                                    key={`t-headings-${index}`}
                                >
                                    {name}
                                </th>
                            )
                        })}
                    </tr>
                )
            }}
            endReached={handleEnd}
            itemContent={(index, data) => {
                const portalUrl = `${process.env.REACT_APP_PORTAL_URL}/#/`;
                return (
                    <>
                        {Object.entries(headings).map((entry) => {

                            const entryKey = entry?.[1]?.key;
                            const postValue = data[entryKey];
                            if (entryKey === 'claimId') {
                                return <td><UUIDLink uuid={data.claimId} link={`${portalUrl}${process.env.REACT_APP_CLAIM_PREFIX}/${data.claimId}`} /></td>
                            }
                            if (entryKey === 'dateSubmitted') {
                                return (<td>{defaultDateFormat(data?.dateSubmitted)}</td>)
                            }
                            if (entryKey === 'timeSubmitted') {
                                return (<td>{defaultTimeFormat(data?.timeSubmitted)}</td>)
                            }
                            if (entryKey === 'Initiated') {
                                return (<td><p>{(data?.roomsSummary["Initiated"])}</p></td>)
                            }
                            if (entryKey === 'Not Processable') {
                                return (<td>{(data?.roomsSummary["Not Processable"])}</td>)
                            }
                            if (entryKey === 'Processing') {
                                return (<td>{(data?.roomsSummary["Processing"])}</td>)
                            }
                            if (entryKey === 'Processed') {
                                return (<td>{(data?.roomsSummary["Processed"])}</td>)
                            }
                            return (<td>{postValue}</td>);
                        })}
                    </>
                )
            }}
        />
    );
};

export default TableBody;