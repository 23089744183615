import React, { useState } from "react";
import CompanyLogo from "../../../assets/images/hosta-ai-Logo.png";
//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import "./MainComponent.scss";
import CollapseIcon from "../../../assets/images/icons/icon-collapse.svg";
import CompanyIcon from "../../../assets/images/hosta-ai-icon.svg";
import PipelineIcon from "../../../assets/images/icons/icon-pipeline.svg";
import ClaimsIcon from "../../../assets/images/icons/icon-analytics.svg";
import "react-pro-sidebar/dist/css/styles.css";
import MainContent from "../MainContent/MainContent";
import { Container } from "react-bootstrap";
import ClaimsComponent from "../ClaimsComponent/ClaimsComponent";

const MainComponent = () => {
  // initial menuCollapse state
  const [selected, setSelected] = useState('pipeline');
  const [menuCollapse, setMenuCollapse] = useState(true);

  // function  menucollapse state from false to true and true to false
  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  return (
    <Container fluid>
      <div className="page__wrapper">
        <div className="left__panel">
          <ProSidebar collapsed={menuCollapse} width={240}>
            <SidebarHeader className="d-flex justify-content-center align-items-center">
              <div className="logotext">
                {/* small and big change using menucollapse state */}

                {menuCollapse ? (
                  <img
                    src={CompanyIcon}
                    className="brand__Icon"
                    alt="hosta-ai__logo"
                  />
                ) : (
                  <img
                    src={CompanyLogo}
                    className="brand__Logo"
                    alt="hosta-ai__logo"
                  />
                )}
              </div>
            </SidebarHeader>
            <SidebarContent>
              <Menu>
                <MenuItem>
                  <div className="closemenu" onClick={menuIconClick}>
                    {/* changing menu collapse icon on click */}
                    {menuCollapse ? (
                      <img
                        src={CollapseIcon}
                        className="close__icon"
                        alt="close-icon"
                        style={{ transform: "rotate(180deg)" }}
                      />
                    ) : (
                      <img
                        src={CollapseIcon}
                        className="close__icon"
                        alt="close-icon"
                      />
                    )}
                  </div>
                </MenuItem>
                <MenuItem
                  onClick={() => setSelected('pipeline')}
                  className="d-flex justify-content-center"
                  active={selected === 'pipeline'}
                  icon={
                    <img
                      src={PipelineIcon}
                      alt="pipeline-icon"
                      className="dashboard_icons"
                    />
                  }
                >
                  {menuCollapse ? "" : "Pipeline"}
                </MenuItem>
                <MenuItem
                  onClick={() => setSelected('claims')}
                  className="d-flex justify-content-center"
                  active={selected === 'claims'}
                  icon={
                    <img
                      src={ClaimsIcon}
                      alt="pipeline-icon"
                      className="dashboard_icons"
                    />
                  }
                >
                  {menuCollapse ? "" : "Claims"}
                </MenuItem>
              </Menu>
            </SidebarContent>
          </ProSidebar>
        </div>
        <div className="main__content">
          {selected === 'pipeline' && <MainContent />}
          {selected === 'claims' && <ClaimsComponent />}
        </div>
      </div>
    </Container>
  );
};

export default MainComponent;
