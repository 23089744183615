import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.scss";

import * as Sentry from "@sentry/react";
import { Navigate, Route, Routes } from "react-router";
import MainComponent from "./components/Dashboard/MainComponent/MainComponent";
import SignUpScreen from "./components/AuthComponents/SignUpScreen/SignUpScreen";
import { LoginScreen } from "./components/AuthComponents/LoginScreen/LoginScreen";
import ForgotPasswordScreen from "./components/AuthComponents/ForgotPasswordScreen/ForgotPasswordScreen";
import ResetPassword from "./components/AuthComponents/ResetPasswordScreen/ResetPassword";
import SignUpConfirmation from "./components/AuthComponents/SignUpScreen/SignUpConfirmation";
import { useAuthStore } from "./stores/auth";
import {useEffect} from "react";
import { useLocation } from 'react-router-dom';

const publicRoutes = ['/', '/login', '/forgot-password', '/reset-password', '/sign-up-confirmation'];

function App() {
  const getSession = useAuthStore((state) => state.getSession);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  
  const location = useLocation();
  
  useEffect(() => {
    getSession();
  }, []);

  function ProtectedRoutes({ children }) {
    if (publicRoutes.includes(location.pathname) && isAuthenticated) {
      return <Navigate to="/dashboard" />;
    }
    
    return isAuthenticated ? children : <Navigate to="/" />;
  }
  
  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="/login" />} />
      <Route path="/sign-up" element={<SignUpScreen />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/sign-up-confirmation" element={<SignUpConfirmation />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoutes>
            <MainComponent />
          </ProtectedRoutes>
        }
      />
    </Routes>
  );
}

export default Sentry.withProfiler(App);
