import React, { useState, useEffect } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SideComponent from "../SideComponent";
import BrandLogo from "../../../assets/images/hosta-ai-Logo-black.png";
import CloseIcon from "../../../assets/images/icons/popup-close.svg";
import "./ForgotPassword.scss";
import { forgotPassword } from 'stores/auth';

const ForgotPasswordScreen = () => {
  const [show, setShow] = useState(true);
  const [popup, setPopup] = useState(false);
  const [forgetPasswordStatus, setForgetPasswordStatus] = useState("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [showButton, setShowButton] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (errorEmail == null && email.length > 0) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [errorEmail, email]);
  const Checks = (e) => {
    const emailCheck = /\S+@\S+\.\S+/;
    if (e.target.name === "email") {
      setEmail(e.target.value);
      if (e.target.value.length > 0) {
        if (!emailCheck.test(e.target.value.trim())) {
          setErrorEmail("Please enter a valid email address");
        } else {
          setErrorEmail(null);
        }
      }
    }
  };

  const handleForgetPassword = async () => {
    const res = await forgotPassword({ email });
    if (res === "send") {
      // A link to reset your password has been sent to your email
      setForgetPasswordStatus(
        "A link to reset your password has been sent to your email"
      );
      setPopup(true);
    } else {
      setForgetPasswordStatus(res.message);
      setPopup(true);
    }
  };

  return (
    <React.Fragment>
      <div className="auth__screen">
        <Container>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="auth-screensModals  p-0"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Body className="p-0">
              <Row>
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  xxl={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div className="auth__screenContent login__screenContent forgot__screen">
                    <div className="brand-logo">
                      <img src={BrandLogo} alt="brand-logo" />
                    </div>
                    <div className="forgotPage__title  my-sm-3 my-md-3 my-lg-4 mb-lg-3 my-xxl-5 mb-xxl-3">
                      <h4 className="mb-0">Forgot password</h4>
                    </div>
                    <div className="page__description">
                      <p className="mb-md-3 mb-sm-4 mb-lg-5 mb-xxl-5">
                        Please enter your email and we will send you a
                        verification code to restore your password
                      </p>
                    </div>
                    <div className="form__section">
                      <Form>
                        <div
                          className={
                            errorEmail
                              ? "field-wrapper mb-1"
                              : "field-wrapper mb-4"
                          }
                        >
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className={errorEmail ? "error-input" : ""}
                            placeholder="Enter your email"
                            // onKeyPress={(e) => checkEnter(e)}
                            onChange={(e) => {
                              Checks(e);
                              // setEmail(e.target.value);
                            }}
                            value={email}
                            required
                          />
                          <div
                            className={
                              errorEmail
                                ? "field-placeholder error-span"
                                : "field-placeholder"
                            }
                          >
                            <span>Email</span>
                          </div>
                        </div>
                        {errorEmail && (
                          <p className="error mb-4">{errorEmail}</p>
                        )}
                        <div className="button__section mt-5">
                          <button
                            disabled={showButton}
                            onClick={(e) => {
                              e.preventDefault();
                              handleForgetPassword();
                            }}
                          >
                            Send verification code
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  xxl={6}
                  className="d-flex justify-content-center d-xs-none d-sm-none d-md-block"
                  id="sidebar-component"
                >
                  <SideComponent />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
      <Modal
        backdropClassName="popup__modalOverlay"
        backdrop="static"
        centered
        size="md"
        className="popup__modal"
        show={popup}
        onHide={() => setPopup(false)}
      >
        <Modal.Body className="p-0">
          <div className="popup__content text-center">
            <div className="popup__title">
              <h4>Verification Code Sent</h4>
              <img
                src={CloseIcon}
                alt="close-icon"
                onClick={() => setPopup(false)}
              />
            </div>
            <div className="popup__description">
              <p className="mb-4">{forgetPasswordStatus}</p>
              <div className="button__section">
                <button
                  className="popup__btn"
                  onClick={() => {
                    forgetPasswordStatus ==
                      "A link to reset your password has been sent to your email"
                      ? navigate("/reset-password", { state: { email: email } })
                      : setPopup(false);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ForgotPasswordScreen;
